import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { message } from "ant-design-vue";
import { AESDecryptPKey, encryptData } from "./nodeRsa";
import { baseUrl } from "./baseUrl";

import store from '../store/index';

/**
 *  const VUE_APP_URL = process.env.VUE_APP_URL;
 *  超时重新请求配置
 */
const axiosConfig = {
  baseURL: baseUrl,
  // timeout: 60000,
  withCredentials: true,
};

// 修改axios配置信息
const service = axios.create(axiosConfig);

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    token && (config.headers["token"] = token); // token
    config.headers["login-type"] = "USER";
    config.headers["content-type"] = "application/json;charset=utf-8";
    config.headers["accept-language"] = localStorage.getItem("lang") || "en-US";

    if (config.method !== "get") {
      // 加密发送的数据 needEn: 是否需要加密 [true | false]
      if (config.data.needEn) {
        config.data = {
          ...encryptData({ result: config.data.data }),
          ip: "12312312",
          time: new Date().getTime(),
        };
      } else {
        config.data = config.data.data;
      }
    }
    store.commit('changeLoadingState', true);

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

service.interceptors.response.use(
  (response) => {

    store.commit('changeLoadingState', false);

    const res = response.data;
    // 错误码
    const errorCode = {
      9001: res.message,
      87321: "您的登录信息已失效",
    };
    if (errorCode[res.code]) {
      message.error(errorCode[res.code]);
    }

    // 尝试解密收到的数据
    try {
      response.data.data = AESDecryptPKey(res.data).result;
    } catch (error) {
      // // console.log('error :>> ', error);
    }

    return response;
  },
  (error) => {
    store.commit('changeLoadingState', false);

    if (error.response && error.response.status === 401) {
      // 对请求状态401额外处理
      (message).destroy(); // 关闭message
      if (error.response.data.code === 87321) {
        localStorage.removeItem('token')
        message.error("您的登录信息已失效!");
        window.location.reload();
      } else {
        message.error(error.response.data.message || "服务器开小差!");
      }
      return Promise.resolve(error.response);
    }
    if (error && error.response) {
      const RESPONSE_CODE = {
        400: "请求参数错误",
        403: "拒绝访问",
        404: "请求超时",
        500: "服务器内部错误",
        501: "服务未实现",
        502: "网关错误",
        503: "服务不可用",
        504: "网关超时",
        505: "HTTP版本不受支持",
      };
      error.message = RESPONSE_CODE[error.response.status] || "服务器开小差!!";
      message.error(error.message);
    }
    return Promise.reject(error);
  }
);

export default service;
