<template>
  <div id="init">
    <div class="init-inner">
      <VHeader>
        <a-icon class="menu" type="menu" @click="showNavBar"></a-icon>
      </VHeader>

      <div class="init-view">
        <RouterView></RouterView>
      </div>
    </div>

    <a-drawer
      title=""
      placement="left"
      :closable="false"
      :visible="visible"
      @close="onClose"
      width="300px"
    >
      <VNavbar @success="onClose"></VNavbar>
    </a-drawer>
  </div>
</template>

<script>
import VNavbar from "./navbar.vue";
import VFooter from "./footer.vue";
import VHeader from "./header.vue";
import Assets from "@/components/assets.vue";
import { RouterView } from "vue-router";
export default {
  components: {
    VNavbar,
    VFooter,
    VHeader,
    Assets,
  },
  data() {
    return {
      collapsed: true,
      hide: window.innerWidth > 576 ? true : false,
      visible: false,
    };
  },
  computed: {
    getPageTitle() {
      return this.$store.state.pageTitle;
    },
  },
  methods: {
    onCollapse(collapsed, type) {
      // // // console.log(collapsed, type);
    },
    onBreakpoint(broken) {
      // // // console.log(broken);
    },
    onMouseEnter() {
      this.collapsed = false;
    },
    onMouseLeave() {
      this.collapsed = true;
    },
    showNavBar() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    onBack() {
      this.$router.back();
    }
  },
};
</script>

<style lang="scss" scoped>
#init {
  width: 500px;
  min-height: 100vh;
  padding-bottom: 20px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  margin: 0 auto;
}

.menu {
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.init-view {
  padding: 0 20px;
}


::v-deep .ant-drawer-body {
  padding: 15px 0;
}

::v-deep .ant-drawer-content {
  padding: 0 15px;
  background: #545454;
}
::v-deep .ant-drawer-content-wrapper {
  border-radius: 0 0 45px 0;
  overflow: hidden;
}


@media screen and (max-width: 576px) {
  #init {
    width: 100%;
    border: 0;
  }
}
</style>
