import Coin from './Coin.json';
import Common from './Common.json';
import Insurance from './Insurance.json';
import Network from './Network.json';
import Package from './Package.json';
import Profile from './Profile.json';
import Transfer from './Transfer.json';
import User from './User.json';
import Wallet from './Wallet.json';
import Level from './Level.json';
import Gaming from './Gaming.json';
import AssetsType from './AssetsType.json';
import Contract from './Contract.json';

export default {
  Coin: { ...Coin, },
  Common: { ...Common, },
  Insurance: { ...Insurance, },
  Network: { ...Network, },
  Package: { ...Package, },
  Profile: { ...Profile, },
  Transfer: { ...Transfer, },
  User: { ...User, },
  Wallet: { ...Wallet, },
  Level: { ...Level, },
  Gaming: { ...Gaming, },
  AssetsType: { ...AssetsType, },
  Contract: { ...Contract, },
}