/**
 *  IP 信息
 */
let baseUrl, imageUrl, socketUrl, hashOrigin, pdfUrl;

// const origin = 'https://login.hashsecure.io';
const origin = 'http://217.79.252.98:21111';
hashOrigin = 'http://217.79.252.98/hs-hash/web/#/';
// hashOrigin = 'http://localhost:8080/hs-hash/web/#/';
// hashOrigin = 'http://login.hashsecure.io/hash-trident/#/';

switch (process.env.NODE_ENV) {
  case "development":

    baseUrl = origin + "/hs-v1";
    imageUrl = origin + "/hs-v1/";
    socketUrl = 'ws://217.79.252.98:21112/ez'
    pdfUrl = '/pdfFile';
    break;
  case "production":

    baseUrl = "https://login.hashsecure.io/hs-v1";
    imageUrl = "https://login.hashsecure.io/hs-v1/";
    socketUrl = 'ws://login.hashsecure.io/ez'
    hashOrigin = 'http://login.hashsecure.io/hash-trident/#/';
    pdfUrl = 'https://login.hashsecure.io';
    break;
}

export { baseUrl, imageUrl, hashOrigin, pdfUrl };
