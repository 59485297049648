const addCommas = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
const numberFixed = (num, len = 2) => {
  return Number(num).toFixed(len)
}

const fixNum = (num) => {
  return addCommas(numberFixed(num));
}

export { addCommas, numberFixed, fixNum }