import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/init.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home.vue'),
        meta: { title: 'DASHBOARD' }
      },
      {
        path: '/announcement',
        name: 'Announcement',
        component: () => import('@/views/announcement/index.vue'),
        meta: { title: 'Announcement' }
      },
      {
        path: '/announcement/details',
        name: 'AnnouncementDetails',
        component: () => import('@/views/announcement/details.vue'),
        meta: { title: 'Announcement' }
      },
      {
        path: '/referrer',
        name: 'Referrer',
        component: () => import('@/views/referrer/index.vue'),
        meta: { title: 'Referrer' }
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/profile/index.vue'),
        meta: { title: 'Profile' }
      },
      {
        path: '/profile/update',
        name: 'UpdateProfile',
        component: () => import('@/views/profile/updateProfile.vue'),
        meta: { title: 'Update Profile' }
      },
      {
        path: '/wallet',
        name: 'Wallet',
        component: () => import('@/views/wallet/index.vue'),
        meta: { title: 'USD Wallet' }
      },
      {
        path: '/deposit',
        name: 'Deposit',
        component: () => import('@/views/wallet/deposit.vue'),
        meta: { title: 'Deposit' }
      },
      {
        path: '/withdraw',
        name: 'Withdraw',
        component: () => import('@/views/wallet/withdraw.vue'),
        meta: { title: 'Withdraw' }
      },
      {
        path: '/wallet/records',
        name: 'WalletRecords',
        component: () => import('@/views/wallet/walletRecords.vue'),
        meta: { title: 'TRANSACTION HISTORY' }
      },
      {
        path: '/package',
        name: 'package',
        component: () => import('@/views/package/index.vue'),
        meta: { title: 'Package' }
      },
      {
        path: '/package/buy',
        name: 'packageBuy',
        component: () => import('@/views/package/buy.vue'),
        meta: { title: 'Package' }
      },
      {
        path: '/package/confirm',
        name: 'packageConfirm',
        component: () => import('@/views/package/confirm.vue'),
        meta: { title: 'Package' }
      },
      {
        path: '/package/upgrade',
        name: 'packageUpdate',
        component: () => import('@/views/package/update.vue'),
        meta: { title: 'Upgrade Package' }
      },
      {
        path: '/package/upgrade/confirm',
        name: 'packageUpdateConfirm',
        component: () => import('@/views/package/updateConfirm.vue'),
        meta: { title: 'Upgrade Package' }
      },
      {
        path: '/profile/2fa',
        name: 'Profile2Fa',
        component: () => import('@/views/profile/googleAuth.vue'),
        meta: { title: 'Set 2FA' }
      },
      {
        path: '/records/:coinName',
        name: 'Records',
        component: () => import('@/views/wallet/records.vue'),
        meta: { title: 'TRANSACTION HISTORY' }
      },
      {
        path: '/change-login-pwd',
        name: 'ChangeLoginPwd',
        component: () => import('@/views/profile/changeLoginPwd.vue'),
        meta: { title: 'Profile' }
      },
      {
        path: '/insurance',
        name: 'insurance',
        component: () => import('@/views/insurance/index.vue'),
        meta: { title: 'Purchase Insurance' }
      },
      {
        path: '/insurance/buy',
        name: 'insuranceBuy',
        component: () => import('@/views/insurance/buy.vue'),
        meta: { title: 'Purchase Insurance' }
      },
      {
        path: '/insurance/records',
        name: 'insuranceRecords',
        component: () => import('@/views/insurance/records.vue'),
        meta: { title: 'Purchase History' }
      },
      {
        path: '/claim/records',
        name: 'claimRecords',
        component: () => import('@/views/insurance/claimRecords.vue'),
        meta: { title: 'Claims History' }
      },
      {
        path: '/transfer',
        name: 'Transfer',
        component: () => import('@/views/transfer/index.vue'),
        meta: { title: 'Transfer' }
      },
      {
        path: '/network',
        name: 'Network',
        component: () => import('@/views/network/index.vue'),
        meta: { title: 'Network' }
      },
      {
        path: '/network/tree/:index/:id',
        name: 'NetworkTree',
        component: () => import('@/views/network/tree.vue'),
        meta: { title: 'Network' }
      },
      {
        path: '/gaming',
        name: 'gaming',
        component: () => import('@/views/gaming/index.vue'),
        meta: { title: 'Gaming' }
      },
      {
        path: '/gaming/records',
        name: 'gamingRecords',
        component: () => import('@/views/gaming/records.vue'),
        meta: { title: 'Gaming History' }
      },
      {
        path: '/purchase-token',
        name: 'purchaseToken',
        component: () => import('@/views/transfer/purchaseToken.vue'),
        meta: { title: '购买代币' }
      },
      {
        path: '/contract',
        name: 'Contract',
        component: () => import('@/views/contract/index.vue'),
        meta: { title: 'Contract' }
      },
      {
        path: '/contract/buy',
        name: 'contractBuy',
        component: () => import('@/views/contract/buy.vue'),
        meta: { title: 'Contract' }
      },
      {
        path: '/contract/confirm',
        name: 'contractConfirm',
        component: () => import('@/views/contract/confirm.vue'),
        meta: { title: 'Contract' }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/user/login.vue'),
    meta: { title: 'Login' }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/user/register.vue'),
    meta: { title: 'Register' }
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('@/views/user/forgot.vue'),
    meta: { title: 'Forgot' }
  },
  { path: '*', component: () => import("@/views/404.vue"), }
]

// 解决报错 NAVIGATION.
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes,
  mode: 'hash'
})


// 不需要登录页面 name
const LOGIN_PAGE_NAME = ['Login', 'Register', '404', 'Forgot'];

// 跳转之前
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (token) {
    next();
  } else {
    if (LOGIN_PAGE_NAME.includes(to.name)) {
      next();
    } else {
      next('/login');
    }
  }
  // next();
})
export default router
