import Vue from 'vue'
import Vuex from 'vuex'
import {
  webUserGetCoinList,
  webUserGetAssetsList,
  webUserGetUserInfo,
  webUserQueryLevelList,
  webUserGetMainCoinTypeList,
  webGetQueryNoticeList,
  webGetQueryMediaList,
  getReturnWallet,
  webUserGetInviteLink,
  webUserGetCountryList
} from "@/api/index" // 接口

Vue.use(Vuex);

let lang = localStorage.getItem('lang') || 'zh-CN';

export default new Vuex.Store({
  state: {
    loading: false,
    lang: lang,
    AssetsList: [],
    UserInfo: {},
    pageTitle: '主页',
    inviteInfo: {},
    countryList: []
  },
  getters: {
    twoStepStatus(state) {
      return state.UserInfo.twoCheckStatus;
    }
  },
  mutations: {
    changeLoadingState(state, val) {
      state.loading = val;
    },
    SET_StoreMutationUserInfo(state, item) {
      state.UserInfo = item;
    },
    SET_StoreMutationAssetsList(state, assets) {
      state.AssetsList = assets;
    },
    SET_StorePageTitle(state, val) {
      state.pageTitle = val;
    },
    SET_StoreInviteLink(state, val) {
      // console.log('val :>> ', val);
      state.inviteInfo = val;
    },
    SET_StoreCountryList(state, val) {
      state.countryList = val;
    }
  },
  actions: {
    async storeActionUserInfo(context) {  // 获取用户信息
      const { data } = await webUserGetUserInfo({});
      if (data.code === 20000) {
        // console.log('UserInfo :>> ', data.data);
        context.commit('SET_StoreMutationUserInfo', data.data);
      }
    },
    async storeActionAssetsList(context) {  // 获取钱包列表
      const { data } = await webUserGetAssetsList({});
      if (data.code === 20000) {
        let records = data.data.records;
        // // console.log('Assets :>> ', records);
        context.commit('SET_StoreMutationAssetsList', records)
      }
    },
    async storeActionInviteLink(context) {  // 获取邀请信息
      const { data } = await webUserGetInviteLink({});
      // // console.log('webUserGetInviteLink :>> ', data.data);
      if (data.code === 20000) {
        let records = data.data;
        context.commit('SET_StoreInviteLink', records)
      }
    },
    async storeActionGetCountryList(context) {  // 获取邀请信息
      const { data } = await webUserGetCountryList({});
      if (data.code === 20000) {
        // let records = data.data;
        let lang = localStorage.getItem("lang") || "en-US";
        let list = data.data.map((item) => {
          return {
            ...item,
            en: lang === "zh-CN" ? item.name : item.en,
          };
        });
        context.commit('SET_StoreCountryList', list)
        
      }
    }
  },
  modules: {
    
  }
})
