<template>
  <div class="header">
    <div class="header-top flex align-center justify-between">
      <div>
        <slot></slot>
      </div>
      <div class="flex align-center">
        <SwitchLang></SwitchLang>
        <a-icon class="bell" type="bell" theme="filled" style="padding-left: 10px;" @click="$router.push('/announcement')" />
      </div>
    </div>

    <div class="page-title flex justify-between" v-if="!$route.path.includes('/network/tree')">
      <div>
        <a-button
          class="back"
          type="link"
          icon="left"
          v-if="$route.path != '/home'"
          @click="onBack"
        ></a-button>
      </div>
      <span>{{ $t('Common.' + $route.meta.title) }}</span>
      <span></span>
    </div>
  </div>
</template>

<script>
import SwitchLang from "@/components/switchLang.vue";
export default {  
  components: {
    SwitchLang,
  },
  data() {
    return {
      lang: localStorage.getItem("lang") || "en-US",
    };
  },
  computed: {
    getUserInfo() {
      return this.$store.state.UserInfo;
    },
    getUserName() {
      return this.getUserInfo.userName;
    },
    getUserLevel() {
      return this.getUserInfo.userLevel;
    },
    getLevelName() {
      let name = "";
      if (this.getUserLevel) {
        name = this.$t("level." + this.getUserLevel);
      }
      return name;
    },
  },
  methods: {
    onLogout() {
      localStorage.removeItem("token");
      this.$router.replace("/login");
    },
    switchLang(value) {
      localStorage.setItem("lang", value);
      this.lang = value;
      window.location.reload();
    },
    onBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding: 10px;
}

.bell {
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.page-title {
  padding: 0 20px;
  margin-top: 20px;
  span {
    font-weight: bold;
    font-size: 24px;
  }

  .back {
    font-size: 22px;
    font-weight: bold;
  }
}

@media screen and (max-width: 576px) {
}
</style>
