<template>
  <div class="assets">
    <div class="row">
      <div class="col" v-for="(item, index) in wallet" :key="index">
        <div class="assets-item">
          <div class="flex align-center justify-between">
            <div class="assets-name gradientText">{{ $t('Coin.' + item) }}</div>
            <div class="percent" v-if="item === 'TOKEN_POINT'">
              {{ $t('Common.价格') }}: {{ tokenPrice }}
            </div>
          </div>

          <div class="amount flex justify-end">
            {{ getAssetObj[item] }}
          </div>

          <div class="flex align-center justify-between">
            <div class="details">
              <a-button type="link" @click="$router.push('/records/' + item)"> {{ $t('Common.Details') }} </a-button>
            </div>
            <div>{{ list[index] }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tokenPrice: {
      type: Number | String,
      default: 0 
    }
  },
  data() {
    return {
      list: [
        'USD',
        'USD$',
        'Token'
      ],
      wallet: [
        "USD_POINT",
        "HS_POINT",
        "TOKEN_POINT",
      ],
      // tokenPrice: 0
    }
  },
  computed: {
    getAssetObj() {
      let obj = {};
      let AssetsList = this.$store.state.AssetsList;
      // // console.log('AssetsList :>> ', AssetsList);
      if (AssetsList.length > 0) {
        AssetsList.forEach(item => {
          obj[item.coinName] = this.fixNum(item.assetsAvailable)
        })
      }
      return obj
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.assets-item {
  margin-top: 25px;
  padding: 12px 12px 8px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 3px 3px #8b8b8b;
  .assets-name {
    font-size: 24px;
    font-weight: bold;
    line-height: 55px;
  }

  .percent {
    font-size: 14px;
    font-weight: bold;
    color: #9B9B9B;
  }

  .amount {
    font-size: 36px;
    font-weight: bold;
    line-height: 40px;
  }
}


</style>