<template>
  <div class="navbar">
    <a-menu mode="inline" :default-selected-keys="[defaultKey]">
      <template v-for="(item, index) in navList">
        <a-menu-item :key="index" v-if="!item.subMenu" @click="onClickMenu(item)">
          <div>
            <!-- <a-icon :type="item.icon" /> -->
            <span class="nav-text">{{ item.label }}</span>
          </div>
        </a-menu-item>
        <a-sub-menu :key="index + ''" v-else>
          <span slot="title">
            <!-- <a-icon :type="item.icon" /> -->
            <span>{{ item.label }}</span>
          </span>
          <a-menu-item :key="index + '-' + subInd" v-for="(sub, subInd) in item.subMenu" @click="onClickMenu(sub)">
            <div class="flex align-center">
              <span class="dot"></span>
              {{ sub.label }}
            </div>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        { label: this.$t('Common.DASHBOARD'), path: '/' },
        { label: this.$t('Common.Profile'), path: '/profile' },
        { label: this.$t('Common.Gaming'), path: '/gaming' },
        { label: this.$t('Common.Package'), path: '/package' },
        { label: this.$t('Common.Insurance'), path: '/insurance', 
          subMenu: [
            { label: this.$t('Common.Purchase Insurance'), path: '/insurance' },
            // { label: 'Submit Claims', path: '/claim' },
            { label: this.$t('Common.View Insurance History'), path: '/insurance/records' },
            { label: this.$t('Common.Claim History'), path: '/claim/records' },
          ]
        },
        { label: this.$t('Common.Contract'), path: '/contract' },
        { label: this.$t('Common.Wallet'), path: '/wallet' },
        { label: this.$t('Common.Network'), path: '/network' },
        { label: this.$t('Common.Logout'), path: '/logout' },
        // { label: 'Helpdesk', path: '/help ' },
      ],
      defaultKey: 0
    }
  },
  methods: {
    onClickMenu({ label, path }) {
      if(path === '/logout') {
        this.onLogout();
      } else {
        this.$store.commit('SET_StorePageTitle', label);
        this.$router.push(path);
      }
      this.$emit('success');
    },
    onLogout() {
      localStorage.removeItem("token");
      this.$router.replace("/login");
    },
  },
  created() {
    let path = this.$route.path;
    this.navList.forEach((item, index) => {
      if (item.path) {
        if (path === item.path) {
          this.defaultKey = index;
          this.$store.commit('SET_StorePageTitle', item.label);
        }
      } else {
        item.subMenu.forEach((sub, subInd) => {
          if (sub.path === path) {
            this.$store.commit('SET_StorePageTitle', sub.label);
            this.defaultKey = index + '-' + subInd;
          }
        })
      }
    })
  }
}
</script>

<style lang="scss" scoped>

.navbar {
  padding: 40px 15px 0;
}
::v-deep .ant-menu {
  background: transparent;
  border: 0;
}

::v-deep .ant-menu-item {
  background-color: transparent !important;
  border-bottom: 1px solid #FEFEFE;
  border-radius: 0 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 100;
  padding: 0 3px !important;
  height: 35px !important;
  line-height: 35px !important;
  margin-bottom: 0 !important;


  &::after {
    border-right: 0 !important;
  }
}

::v-deep .ant-menu-submenu-title {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 100;
  padding: 0 3px !important;
  border-bottom: 1px solid #FEFEFE;
  height: 35px !important;
  line-height: 35px !important;
  margin-bottom: 0 !important;

  .ant-menu-submenu-arrow {
    &::after {
      background: #FFF !important;
    }

    &::before {
      background: #FFF !important;
    }
  }
}

::v-deep .ant-menu-sub {
  background-color: transparent !important;

  .ant-menu-item {
    border-bottom: 0 !important;
    font-size: 16px !important;
    padding-left: 10px !important;
    height: 32px !important;
    line-height: 32px !important;
  }
}

::v-deep .ant-menu-submenu-open {
  // border-bottom: 1px solid #FEFEFE !important;

  // .ant-menu-submenu-title {
  //   border: 0 !important;
  // }

  // .ant-menu-sub {
  //   padding: 0px 0 15px !important;
  // }
}

.dot {
  margin-right: 10px;
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #FFF;
  border-radius: 50%;
}


@media screen and (max-width: 576px) {

}
</style>
::v-deep .ant-menu-submenu-title