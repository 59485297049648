<template>
  <div id="app">
    <a-spin :spinning="getLoading">
      <a-config-provider :locale="localData[locale]">
        <router-view />
      </a-config-provider>
    </a-spin>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import enUS from 'ant-design-vue/lib/locale-provider/en_US';
export default {
  data() {
    return {
      locale: localStorage.getItem('lang') || 'en-US',
      localData: {
        'zh-CN': zhCN,
        'en-US': enUS
      }
    }
  },
  computed: {
    getLoading() {
      return this.$store.state.loading
    }
  },
  created() {
    const token = localStorage.getItem('token');
    if (token) {
      this.$store.dispatch('storeActionUserInfo');
      this.$store.dispatch('storeActionAssetsList');
      this.$store.dispatch('storeActionInviteLink');
      this.$store.dispatch('storeActionGetCountryList');
    }
  }
}
</script>

<style>
@import url(./common/common.scss);

html {
  --primary: #3DA874;
  --border: #545454;
}

#app {
  box-sizing: border-box;
  /* width: 100vw; */
  background-color: #fff;
}

div {
  box-sizing: border-box;
}

.ant-btn-link {
  padding: 0 !important;
  height: 24px !important;
}

.gradientText {
  background-image: linear-gradient(to right,#E787B6 20%, #7AC3AB 70%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card {
  padding: 12px 20px 8px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 3px 3px #8b8b8b;
}

.submit-btn {
  width: 100% !important;
  border-radius: 10px !important;
  height: 45px !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

</style>
